<template>
    <b-card id="profile-page">
        <slot name="header">
            <h5 slot="header" class="title">{{ t('profile') }}</h5>
        </slot>
        <form @submit.prevent="save" id="formProfile">
            <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
                <b-row>
                    <b-col md="12">
                        <fg-input v-if="editable"
                                    v-model="profile.name"
                                    style="max-width:350px"
                                    id="name"
                                    :label="t('name')"
                                    name="name"
                                    :placeholder="t('name')"
                                    v-validate="validations.name"
                                    :error="getError('name')"
                                    autocapitalize="yes">
                        </fg-input>
                        <fg-input v-else>
                            <label id='label-name' class="col-form-label">{{ t('name') }}:</label>
                            <span class="form-control-static ml-2">{{ profile.name }}</span>
                        </fg-input>
                    </b-col>
                    <b-col md="12">
                        <fg-input v-if="editable"
                                    v-model="profile.email"
                                    style="max-width:350px"
                                    id="email"
                                    :label="t('email')"
                                    name="Email"
									                  v-validate="validations.email"
                                    :error="getError('Email')"
                                    :placeholder="t('email')"
                                    autocapitalize="none">
                        </fg-input>
                        <fg-input v-else>
                            <label  id='label-email' class="col-form-label">{{ t('email') }}:</label>
                            <span class="form-control-static ml-2">{{ profile.email }}</span>
                        </fg-input>
                    </b-col>
                    <b-col md="12">
                        <fg-input v-if="editable"
                                    v-model="phoneNumber"
                                    style="max-width:350px"
                                    id="phone"
                                    :label="t('phone')"
                                    name="Phone"
                                    v-validate="validations.phone"
                                    :error="getError('Phone')"
                                    :placeholder="t('phone')"
                                    autocapitalize="none">
                        </fg-input>
                    </b-col>
                    <b-col v-if="languageOptions" xl="4" class="mb-2">
                        <div class="form-group has-label"><label>{{ t('preferred_language') }}</label></div>
                        <el-select class="select-primary"
                            size="large"
                            placeholder="Language"
                            v-model="profile.language_id">
                            <el-option v-for="option in languageOptions"
                                class="select-primary"
                                :value="option.key"
                                :label="option.value"
                                :key="option.key">
                            </el-option>
                        </el-select>
                    </b-col>
                    <b-col v-if='false' xl="8" class="mb-2">
                        <div class="form-group has-label"><label>{{ t('preferred_time_zone') }}</label></div>
                        <el-select class="select-primary"
                            size="large"
                            placeholder="Time Zone"
                            v-model="profile.time_zone">
                            <template v-for="option in $options.timezones" >
                                <el-option v-for="item in option.utc"
                                    class="select-primary"
                                    :value="item"
                                    :label="option.text + ' - ' + item"
                                    :key="item.key">
                                </el-option>
                            </template>
                        </el-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="7" v-if="!newAdmin" class="pt-4 ml-1">
                        <span>
                            <i class="now-ui-icons ui-1_calendar-60"></i>
                                {{ t('created_at') }}: {{ toLocaleString(datetimeFromISO(profile.created_at)) }}
                        </span>
                    </b-col>

                    <b-col v-if="editable" cols="6" md="4" class="ml-auto">
                        <n-button native-type="submit" type="primary" id="btn_submit_manager" round block :visible_for_permissions="visible_for_permissions">
                            <template v-if="newAdmin">
                                {{ t('Create') }}
                            </template>
                            <template v-else>
                                {{ t('Save') }}
                            </template>
                        </n-button>
                    </b-col>
                </b-row>
            </b-container>
        </form>
        <template v-slot:footer class="" v-if="!newAdmin">
            <div class="hr-grey mb-2"/>
            <i class="now-ui-icons arrows-1_refresh-69"></i>
                {{t('Last Updated')}} : {{ toLocaleString(datetimeFromISO(profile.updated_at))}}
        </template>
    </b-card>
</template>


<script>
import timezones_list from './timezones.json'
import { emailRegExp, phoneRegExp } from "../../util/regexConsts";
import { Select, Option } from 'element-ui';
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'edit-profile-form',
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
    },
    props: {
        value: {
            type: Object
        },
        editable: {
            type: Boolean,
            default: false
        },
        visible_for_permissions: {
            type: Array,
            default: function () { return [] }
        },
        isIspAdmin: {
            type: Boolean,
            default: false
        }
    },
    timezones: timezones_list,
    data() {
        return {
            profile: {},
            newAdmin: true,
            phoneNumber: '',
            validations:{
                name:{
                    min: 2,
                    max: 256
                },
                email:{
                    max: 128,
                    regex: emailRegExp
                },
                phone:{
                    min: 7,
                    regex: phoneRegExp,
                    max: 20
                }
            },
        }
    },
    async created() {
      await this.getNotificationsAllConfiguration();
    },
    mounted () {
      if ( !!this.value){
        this.profile = this.value;
        this.phoneNumber = this.value.phone_number
        if ( this.profile.user_id)
          this.newAdmin = false
      }
    },
    computed: {
      ...mapGetters("ispServices/notifications", ["langOptions"]),
	  ...mapGetters('settings', ['getLanguages']),
      languageOptions() {
        if (!_.isEmpty(this.langOptions) && !this.isIspAdmin) {
           return Object.values(this.langOptions).map(option => ({
            key: option.value,
            value: option.name
          }))
        } else if (this.isIspAdmin){
            return this.getLanguages.map(option => ({
                key: option.id + '-' + option.regionCode,
                value: option.name
            }))
        }
      }
    },
    watch: {
        value(newValue, oldValue) {
            this.profile = newValue;
            this.phoneNumber = newValue.phone_number
            if ( this.profile.user_id)
                this.newAdmin = false
        },
        phoneNumber(newValue, oldValue){
            if(this.validations.phone.regex.test(newValue)){
                this.profile.phone_number = newValue
            }
            else{
                this.phoneNumber = oldValue
            }
        }
    },
    methods: {
        ...mapActions("ispServices/notifications", ["getNotificationsAllConfiguration"]),
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async save(){
            let isValid = await this.$validator.validateAll();
            if(isValid){
                this.$emit('input', this.profile)
            }
        }
    }

}
</script>
