<template>
    <b-card class="card-user" >
        <slot name="header" :class="{ inactive: identity.blocked}">
            <h5 slot="header" class="title">{{t('Authentication')}}</h5>
        </slot>
        <div class="author" :class="{ inactive: identity.blocked}">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
            <div class="block block-three"></div>
            <div class="block block-four"></div>

            <avatar
                :fullname="identity_name"
                :usewords="!!identity_name"
                shine-on-hover
                zoom-on-hover
                :size="124"
                :class="{ inactive: !identity.user_id}"
            />
            <fg-input v-model="identity.user_id"
                    id="user_id"
                    v-validate="modelValidations.username"
                    name="login name"
                    :error="getError('login name')"
                    class="no-border no-height form-control-lg mb-5"
                    :placeholder="t('username')"
                    addon-left-icon="now-ui-icons users_single-02"
                    autocapitalize="none"
                    style="font-size:1rem;max-width: 70%; margin: 10px auto 20px auto;"
                    :readonly="!newAdmin" />
        </div>


        <form @submit.prevent="save" >
            <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
                <b-row align-h="center" >
                    <template v-if="isOperationsAdmin && !newAdmin">
                        <b-col sm="5" class="mt-1">
                            <label class="col-form-label">{{t('Blocked')}}:</label>
                        </b-col>
                        <b-col sm="5">
                            <n-switch v-model="identity.blocked" @input="toggleButton($event)"/>
                        </b-col>
                    </template>
                    <template v-if="identity.identity_provider_type !== null">
                        <b-col sm="5" class="mt-1">
                            <label class="col-form-label">{{t('Identity Provider')}}:</label>
                        </b-col>
                        <b-col sm="5" v-if="!newAdmin">
                            <fg-input>
                                <p class="form-control-static">{{ identity.identity_provider_type.replace('IdentityProvider', '') }}</p>
                            </fg-input>
                        </b-col>
                        <b-col sm="5" v-else>
                            <el-select class="select-primary"
                                size="large"
                                :placeholder="this.t('Identity Provider')"
                                v-validate="'required'"
                                :error="getError('IdentityProvider')"
                                name="IdentityProvider"
                                v-model="identity.identity_provider_type">
                                <el-option v-for="option in $options.identityProviders"
                                    class="select-primary"
                                    :value="option.key"
                                    :label="option.value"
                                    :key="option.key">
                                </el-option>
                            </el-select>
                        </b-col>
                    </template>
                    <template v-if="account_type == 'isp' && isOperationsAdmin">
                        <b-col sm="5" class="mt-1">
                            <label class="col-form-label">{{t('role_label')}}:</label>
                        </b-col>
                        <b-col sm="5">
                            <el-select class="select-primary"
                                size="large"
                                :placeholder="this.t('role_label')"
                                :error="getError('role_label')"
                                :disabled="roleSelectorDisabled()"
                                name="role_label"
                                @change="updateRole($event)"
                                v-model="identity.roles">
                                <el-option v-for="option in availableRoles"
                                    class="select-primary"
                                    :value="option.key"
                                    :label="option.value"
                                    :key="option.key">
                                </el-option>
                            </el-select>
                        </b-col>
                    </template>
                </b-row>
                <b-row v-if="changePassword">
                    <b-col :key='passwordFieldKey'>
                        <fg-input v-if="false" :label="t('Current password')"
                            :data-vv-as="t('current password')"
                            autocomplete="current-password"
                            required
                            type="password"
                            name="currentPassword"
                            v-validate="modelValidations.currentPassword"
                            :error="getError('currentPassword')"
                            v-model="model.currentPassword">
                        </fg-input>

                        <fg-input :label="t('New password')"
                            :data-vv-as="t('new password')"
                            autocomplete="new-password"
                            required
                            type="password"
                            name="newPassword"
                            ref="newPassword"
                            v-validate="modelValidations.newPassword"
                            :error="getError('newPassword')"
                            v-model="model.newPassword">
                        </fg-input>

                        <fg-input :label="t('Confirm password')"
                            :data-vv-as="t('confirm password')"
                            autocomplete="new-password"
                            required
                            type="password"
                            name="newPasswordConfirm"
                            v-validate="modelValidations.newPasswordConfirm"
                            :error="getError('newPasswordConfirm')"
                            v-model="model.newPasswordConfirm">
                        </fg-input>

                        <hr style="border-top: 0px"/>

                        <div id='passwordLogic' class="category form-category password-requirements">
                          <span v-if="hasPasswordConditions">{{ t('must_include') }}</span>
                          <div v-if='checkIncludeChar'>
                            - {{ passwordComplexity.minimumPasswordLength }} {{ t('must_include_char') }}
                          </div>
                          <div v-if='checkIncludeNumb'>
                            - {{ passwordComplexity.minimumNumberDigitsRequired }} {{ t('must_include_numb') }}
                          </div>
                          <div v-if='checkIncludeUpp'>
                            - {{ passwordComplexity.minimumUppercaseRequired }} {{ t('must_include_upp') }}
                          </div>
                          <div v-if='checkIncludeLow'>
                            - {{ passwordComplexity.minimumLowercaseRequired }} {{ t('must_include_low') }}
                          </div>
                          <div v-if='checkIncludeSym'>
                            - {{ passwordComplexity.minimumSymbolsRequired }} {{ t('must_include_sym') }}
                          </div>
                          <div v-if='checkIncludeMidNum'>
                            - {{ passwordComplexity.minimumMiddleNumberDigitsRequired }} {{ t('must_include_mid_num') }}
                          </div>
                          <div v-if='checkIncludeMidSym'>
                            - {{ passwordComplexity.minimumMiddleSymbolsRequired }} {{ t('must_include_mid_sym') }}
                          </div>
                          <div v-if="checkIncludeDiff">
                            - {{ getDiffMessage }}
                          </div>

                          <hr style="border-top: 0px"/>

                          <span v-if="hasAdditionalPasswordCondition">{{ t('password_addition') }}</span>
                          <div v-if="passwordComplexity.check_keyboard_pattern">
                            - {{ t('check_keyboard_pattern') }}
                          </div>
                          <div v-if="passwordComplexity.check_mirrored_sequence">
                            - {{ t('check_mirrored_sequence') }}
                          </div>
                          <div v-if="passwordComplexity.check_repeated_sequence">
                            - {{ t('check_repeated_sequence') }}
                          </div>
                          <div v-if="passwordComplexity.check_sequential_letters">
                            - {{ t('check_sequential_letters') }}
                          </div>
                          <div v-if="passwordComplexity.check_sequential_numbers">
                            - {{ t('check_sequential_numbers') }}
                          </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="changePassword">
                    <b-col lg="8" xl="6" class="ml-auto">
                        <n-button native-type="submit" type="primary" id="btn_submit" round block :visible_for_permissions="visible_for_permissions">
                            <template v-if="newAdmin">
                                {{ t('Create') }}
                            </template>
                            <template v-else>
                                {{ t('Set Password') }}
                            </template>
                        </n-button>
                    </b-col>
                </b-row>
            </b-container>
        </form>

        <template v-slot:footer class="" v-if="!newAdmin">
            <div class="hr-grey mb-2"/>
            <div v-if="!identity.blocked">
                <i class="mr-1"><svgicon class="icon" icon="enter" width="15" height="15" /></i>{{t('Last IP seen')}}: {{ identity.last_ip}}
            </div>
            <div v-if="!identity.blocked">
                <i class="mr-1"><svgicon class="icon" icon="key" width="15" height="15" /></i>{{t('Last login')}}: {{ toLocaleString(datetimeFromISO(identity.last_login))}}
            </div>
            <div v-if="!identity.blocked">
                <i class="mr-1"><svgicon class="icon" icon="user" width="15" height="15" /></i>{{t('Last password reset')}}: {{ toLocaleString(datetimeFromISO(identity.last_password_reset)) }}
            </div>

            <div v-else>
                <i class="now-ui-icons ui-1_simple-remove mr-1 red"></i>{{t('This account is blocked!')}}
            </div>

        </template>
    </b-card>
</template>

<script>
import { Switch } from '@/components';
import { Select, Option } from 'element-ui';
import Config from '@/config'

export default {
    name: 'identity-card',
    components: {
        [Switch.name]: Switch,
        [Option.name]: Option,
        [Select.name]: Select,
    },
    environments: {
        'residential': 'res',
        'enterprise_groups': 'ent',
        'isp' : 'isp',
        'business' : 'res'
    },
    props: {
        value: {
            type: Object
        },
        changePassword: {
            type: Boolean,
            default: false
        },
        account_type : {
            type: String,
            default: 'isp'
        },
        isOperationsAdmin: {
            type: Boolean,
            default: false
        },
        visible_for_permissions: {
            type: Array,
            default: function () { return [] }
        }
    },
    identityProviders: [
        {
            key: 'IdentityProviderDatabase',
            value: 'Database'
        }
    ],
    data() {
        return {
            identity: { identity_provider_type: 'IdentityProviderDatabase' },
            newAdmin: true,
            model: {
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: ''
            },
            modelValidations: {
                currentPassword: {
                    required: true,
                },
                newPassword: {
                    required: true,
                    min: null,
                    regex: /^[\000-\177]*$/
                },
                newPasswordConfirm: {
                    required: true,
                    confirmed: 'newPassword',
                    regex: /^[\000-\177]*$/
                },
                username: {
                    required: true,
                    max: 256,
                    regex: /^[\000-\177]*$/
                }
            },
            passwordComplexity: {
                minimumLowercaseRequired: null,
                minimumMiddleNumberDigitsRequired: null,
                minimumMiddleSymbolsRequired: null,
                minimumNumberDigitsRequired: null,
                minimumPasswordLength: null,
                minimumSymbolsRequired: null,
                minimumUppercaseRequired: null,
                historyCheckDepth: null,
                check_keyboard_pattern: false,
                check_mirrored_sequence: false,
                check_repeated_sequence: false,
                check_sequential_letters: false,
                check_sequential_numbers: false
            },
            passwordFieldKeyIterator: 0
        }
    },
    watch: {
        value(newValue, oldValue) {
            this.identity = newValue;
            if ( this.identity.user_id)
                this.newAdmin = false
        },
        blocked(newValue, oldValue){
            this.$emit('input', this.identity)
        }
    },
    mounted () {
        this.getPasswordConfiguration();
        if ( !!this.value)
            this.identity = this.value;
            if ( this.identity.user_id)
                this.newAdmin = false
    },
    computed: {
        identity_name: {
            get(){
                if ( this.identity){
                    if( this.identity.name)
                        return this.identity.name
                    else
                        return this.identity.user_id
                }
                else{
                    return ''
                }
            }
        },
        availableRoles: {
            get() {
                return Array.from(this.$store.getters["generalManagement/administrators/asm_roles"].keys()).map(role => ({key: role, value: this.t(role == "Troubleshooting" ? role + "_ROL" : role )}));
            }
        },
        hasPasswordConditions() {
            return (this.checkIncludeChar || this.checkIncludeNumb || this.checkIncludeUpp
                || this.checkIncludeLow || this.checkIncludeSym || this.checkIncludeMidNum
                || this.checkIncludeMidSym || this.checkIncludeDiff)
        },
        hasAdditionalPasswordCondition() {
          return this.passwordComplexity &&
          (!!this.passwordComplexity.check_keyboard_pattern  || !!this.passwordComplexity.check_mirrored_sequence || !!this.passwordComplexity.check_repeated_sequence || !!this.passwordComplexity.check_sequential_letters || !!this.passwordComplexity.check_sequential_numbers)
        },
        checkIncludeChar(){
          return !!this.passwordComplexity.minimumPasswordLength;
        },
        checkIncludeNumb(){
          return !!this.passwordComplexity.minimumNumberDigitsRequired;
        },
        checkIncludeUpp(){
          return !!this.passwordComplexity.minimumUppercaseRequired;
        },
        checkIncludeLow(){
          return !!this.passwordComplexity.minimumLowercaseRequired;
        },
        checkIncludeSym(){
          return !!this.passwordComplexity.minimumSymbolsRequired;
        },
        checkIncludeMidNum(){
          return !!this.passwordComplexity.minimumMiddleNumberDigitsRequired;
        },
        checkIncludeMidSym(){
          return !!this.passwordComplexity.minimumMiddleSymbolsRequired;
        },
        checkIncludeDiff(){
          return !!this.passwordComplexity.historyCheckDepth;
        },
        getDiffMessage() {
          let historyCheck = this.passwordComplexity.historyCheckDepth;
          return historyCheck === 1
            ? this.t('must_include_diff_current')
            : `${this.t('must_include_diff_1')} ${historyCheck} ${this.t('must_include_diff_2')}`
        },
        passwordFieldKey() {
          return `password-fields_${this.passwordFieldKeyIterator}`;
        },
        languages(){
            return this.$store.getters["settings/getLanguages"];
        }
    },
    methods: {
        async getPasswordConfiguration() {
          try {
            this.$store.commit('settings/startLoading', true);
            if( this.account_type ){
                let env = this.$options.environments[this.account_type]
                await this.$store.dispatch('providers/getProviderDetails', { type: env, id: this.identity.identity_provider_type })
                const consolidatedConfiguration = this.$store.getters[`providers/${env}ProviderDetails`];
                const { password_complexity, password_rotation } = consolidatedConfiguration;

                Object.assign(this.passwordComplexity, {
                    minimumLowercaseRequired: password_complexity.minimum_lowercase_required,
                    minimumMiddleNumberDigitsRequired: password_complexity.minimum_middle_number_digits_required,
                    minimumMiddleSymbolsRequired: password_complexity.minimum_middle_symbols_required,
                    minimumNumberDigitsRequired: password_complexity.minimum_number_digits_required,
                    minimumPasswordLength: password_complexity.minimum_password_length,
                    minimumSymbolsRequired: password_complexity.minimum_symbols_required,
                    minimumUppercaseRequired: password_complexity.minimum_uppercase_required,
                    historyCheckDepth: password_rotation.history_check_depth,
                    check_keyboard_pattern: password_complexity.check_keyboard_pattern,
                    check_mirrored_sequence: password_complexity.check_mirrored_sequence,
                    check_repeated_sequence: password_complexity.check_repeated_sequence,
                    check_sequential_letters: password_complexity.check_sequential_letters,
                    check_sequential_numbers: password_complexity.check_sequential_numbers
                });

                this.modelValidations.newPassword.min = this.passwordComplexity.minimumPasswordLength;
                this.rerenderPasswordFields();
            }
            this.$store.commit('settings/finishLoading', true);
          } catch (err) {
            this.$store.commit('settings/finishLoading', true);
            console.log(err);
          }
        },
        rerenderPasswordFields() {
          this.passwordFieldKeyIterator += 1;
        },
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async save(){
            let isValid = await this.$validator.validateAll();
            if (isValid){
                this.identity[this.identity.identity_instance_type].password = this.model.newPassword
                this.$emit('input', this.identity)
                setTimeout(() => {
                    this.model.currentPassword = this.model.newPassword = this.model.newPasswordConfirm = '';
                    this.$validator.reset();
                }, 100)
            }
        },
        toggleButton(event){
            this.identity.blocked = event
            this.$emit('input', this.identity)
        },
        updateRole(event){
            if (!this.newAdmin) {
                this.identity.roles = event
                this.$emit('input', this.identity)
            }
        },
        roleSelectorDisabled() {
            let res;
            (this.identity.user_id == this.$store.getters["auth/id"]) ? res = true : res = false;
            return res;
        }
    }

}
</script>

<style scoped lang="scss">
  .password-requirements {
    color: #344675;
    text-transform: none;
  }
</style>
